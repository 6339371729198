import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService } from './shared/services/auth.service';
import { INgxLoadingConfig, NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    NgxLoadingModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.sass'
})
export class AppComponent {
  auth = inject(AuthService);
  title = 'revitauto';

  public config: INgxLoadingConfig = {
    animationType: ngxLoadingAnimationTypes.chasingDots,
    fullScreenBackdrop: true,
    backdropBorderRadius: "4px",
    primaryColour: "#45047A",
    secondaryColour: "#cc3546",
    tertiaryColour: "#16b542",
  };

  get isOverlay() {
    return this.auth.isOverlay;
  }
}
