import { Routes } from '@angular/router';
import { guardOnlyLoginUser, guardPrventLogin } from './pages/auth/auth.guard';
import { eRPath, eRRoute } from './shared/enums/er-routes';
import { managerGuard } from './shared/guards/qp.guard';

export const routes: Routes = [
  {
    path: eRRoute.chatMessage,
    canActivate: [guardOnlyLoginUser],
    loadComponent: () => import('./pages/chat/chat-message/chat-message.component').then(m => m.ChatMessageComponent)
  },
  {
    path: eRRoute.analytics,
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/dashboard/analytics.routes'),
  },
  {
    path: eRRoute.map,
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/map/map.routes'),
  },
  {
    path: eRRoute.repairRequest,
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/features/repair-request/repair-request.routes')
  },
  {
    path: eRRoute.serviceRecords,
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/features/service-records/service-records.routes')
  },
  {
    path: 'vehicle',
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/vehicle/vehicle.routes'),
  },
  {
    path: 'reminder-profiles',
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/features/reminder-profiles/reminder-profiles.routes'),
  },
  {
    path: 'inspection-titles',
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/features/inspection-titles/inspection-titles.routes'),
  },
  {
    path: eRRoute.dtiles,
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/features/diagnostics-titles/diagnostics-titles.routes'),
  },
  {
    path: eRRoute.tripInspections,
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/features/trip-inspections/trip-inspections.routes'),
  },
  {
    path: eRRoute.manageVehicles,
    canActivate: [guardOnlyLoginUser, managerGuard],
    loadChildren: () => import('./pages/features/manage-vehicles/manage-vehicles.routes'),
  },
  {
    path: eRRoute.diagnostics,
    canActivate: [guardOnlyLoginUser, managerGuard],
    loadChildren: () => import('./pages/features/diagnostics/diagnostics.routes'),
  },
  {
    path: eRRoute.home,
    canActivate: [guardOnlyLoginUser],
    loadComponent: () => import('./pages/home/home.component').then(m => m.HomeComponent)
  },
  {
    path: eRRoute.timesheet,
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/features/timesheet/timesheet.routes'),
  },
  {
    path: eRRoute.companies,
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/features/companies/companies.routes'),
  },
  {
    path: eRRoute.vtl,
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/features/video-training-library/video-training-library.routes'),
  },
  {
    path: eRRoute.estimate,
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/features/estimate/estimate.routes'),
  },
  {
    path: eRRoute.serviceRecords,
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/features/service-records/service-records.routes'),
  },
  {
    path: eRRoute.obd2DevicesList,
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/features/obd2-devices/obd2-devices.routes'),
  },
  {
    path: eRRoute.reminders,
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/features/reminders/reminders.routes'),
  },
  {
    path: eRRoute.usersList,
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/features/users/users.routes'),
  },
  {
    path: eRRoute.fleetHealth,
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/features/fleet-health/fleet-health.routes'),
  },
  {
    path: eRRoute.statistics,
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/features/statistics/statistics.routes'),
  },
  {
    path: eRRoute.profile,
    canActivate: [guardOnlyLoginUser],
    loadChildren: () => import('./pages/profile/profile.routes'),
  },
  {
    path: eRRoute.auth,
    canActivate: [guardPrventLogin],
    loadChildren: () => import('./pages/auth/auth.routes'),
  },
  { path: eRRoute.empty, pathMatch: 'full', redirectTo: eRPath.home },
  { path: eRRoute.all, redirectTo: eRPath.root },
];
